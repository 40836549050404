import React, { useState } from "react"
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form";
import LearnMoreHub from "../components/sections/hubs/learn-more-hub";

import bannerImg from "../images/software-platform/header.svg"
import heroImg from "../images/software-platform/sp_hero_image.png"
import securityImg from "../images/software-platform/security.png"

import securityIcon from "../images/software-platform/network-tools/security.svg"
import reducedIcon from "../images/software-platform/network-tools/reduced_it.svg"
import analyticsIcon from "../images/software-platform/network-tools/helpful_analytics.svg"

import tab1Img from "../images/software-platform/what-you-can-do/tab/q2platform-screenshot1.png"
import tab2Img from "../images/software-platform/what-you-can-do/tab/q2platform-screenshot2.png"
import tab3Img from "../images/software-platform/what-you-can-do/tab/q2platform-screenshot3.png"
import tab4Img from "../images/software-platform/what-you-can-do/tab/q2platform-screenshot4.png"
import tab5Img from "../images/software-platform/what-you-can-do/tab/q2platform-screenshot5.png"

import easily_connect from "../images/software-platform/what-you-can-do/easily_connect.svg"
import generate_helpful from "../images/software-platform/what-you-can-do/generate_helpful.svg"
import manage_users from "../images/software-platform/what-you-can-do/manage_users.svg"
import peer_with_workflow from "../images/software-platform/what-you-can-do/peer_with_workflow.svg"
import remain_protected from "../images/software-platform/what-you-can-do/remain_protected.svg"

import advance_platform_bg from "../images/software-platform/advance_platform_bg.svg"
import advance_software from "../images/software-platform/advance_software.png"

import Banner from "../components/sections/banner";

// import redDot from "../images/software-platform/what-you-can-do/red_dot.gif";

const SoftwarePlatform = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (<Layout title="Software Platform">
    <SEO title="Software Platform - Internet and Wi-Fi Software - VostroNet">
      <meta name="description" content="The VostroNet intuitive Q2 software platform provides a dashboard for internet and wi-fi management. The software offers advance analytics and integration." />
    </SEO>
    <ContactForm show={show} handleClose={handleClose} />
    <Header handleShow={handleShow} />
    <InfoSection1 />
    <InfoSection2 />
    <InfoSection3 handleShow={handleShow} />
    <LearnMoreHub />
  </Layout>)
}

const Header = ({ handleShow }) => {
  return (<>
    <Banner className="font-white" bgImg={bannerImg} data={{
      title: {
        name: "Manage your wireless environment",
      },
      subtitles: [
        {
          name: "Our powerful intuitive software platform places us a step above on the podium.",
          className: "banner-text-small",
        },
      ],
      img: heroImg,
      imgAlt: "software platform hero",
      imgNoShadow: true,
    }}
      hideScrollButton
      customButtons={(<>
        <Container>
          <Row>
            <Col xs="auto">
              <Button variant="yellow" className="btn-banner mb-3" onClick={handleShow}>
                <i className="fas fa-calendar-star mr-2" />
                {"Book a demo"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button variant="outline-light" target="_blank" rel="noopener" href="https://cdn.vostro.cloud/content/vostronet-q2-platform.pdf">
                <i className="fas fa-binoculars mr-2" />
                {"Quick overview"}
              </Button>
            </Col>
          </Row>
        </Container>
      </>)}
    />
  </>);
};

const InfoSection1 = () => {
  return (
    <Container className="general py-5">
      <Row>
        <Col>
          <div className="title text-center pt-5 mb-4">
            {"Powerful network tools and features"}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="desc text-center mb-5">
            {"A cloud-based Wi-Fi management system that centralises and automates the management of a Wi-Fi environment."}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={7}>
          <img src={securityImg} alt="software platform hero" className="sp-img" />
        </Col>
        <Col xs={12} lg={5}>
          <Accordion defaultActiveKey="0" className="sp-accordion">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <Row>
                  <Col xs={2}>
                    <img src={securityIcon} alt="security" className="accordion-icon mr-3" />
                  </Col>
                  <Col xs={8}>
                    {"Security as a priority"}
                  </Col>
                  <i className="fas fa-angle-down ml-auto my-auto" />
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="small-text">{"Architecture designed with enhanced cybersecurity features. Enterprise-level encryption, DNS protection/malware prevention and PrivateMesh standard"}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <Row>
                  <Col xs={2}>
                    <img src={reducedIcon} alt="reduced it touchpoints" className="accordion-icon mr-3" />
                  </Col>
                  <Col xs={8}>
                    {"Reduced IT touchpoints"}
                  </Col>
                  <i className="fas fa-angle-down ml-auto my-auto" />
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="small-text">{"Our Platform automates and simplifies the manual work required by IT staff in setting up and maintaining different Wi-Fi networks for different groups."}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <Row>
                  <Col xs={2}>
                    <img src={analyticsIcon} alt="Helpful Network and User Analytics" className="accordion-icon mr-3" />
                  </Col>
                  <Col xs={8}>
                    {"Helpful Network and User Analytics"}
                  </Col>
                  <i className="fas fa-angle-down ml-auto my-auto" />
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body className="small-text">{"Helpful Network and User Analytics Learn about your wireless environment with helpful analytics about the network and users within - a valuable tool for network management."}</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

const InfoSection2 = () => {

  const [activeMenu, setActiveMenu] = useState(0);

  const renderMenu = () => {
    const menuData = [
      {
        desc: "Generate helpful analytics for your wireless venue",
        img: generate_helpful,
      },
      {
        desc: "Remain protected when using the network",
        img: remain_protected,
      },
      {
        desc: "Peer with workflow and CRM systems",
        img: peer_with_workflow,
      },
      {
        desc: "Manage users and spaces via room and resource management",
        img: manage_users,
      },
      {
        desc: "Easily connect to the network via the major logins",
        img: easily_connect,
      },
    ];

    return (<>
      {menuData.map(({ img, desc }, index) => {
        return (<Row key={index}
          className={`${index === activeMenu ? "active" : ""} tab-menu-item mb-2`}
          onClick={() => setActiveMenu(index)}>
          <Col xs={12}>
            <div className={`${index === activeMenu ? "paper" : "no-paper"}`}>
              <Container fluid className="no-gutters">
                <Row
                  className={"align-items-center"}>
                  <Col xs="auto">
                    <div style={{ width: 50 }}>
                      <img src={img} alt={desc} className="icon img-fluid" />
                    </div>
                  </Col>
                  <Col>
                    <div className="desc ml-3">
                      {desc}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>

        );
      })}
    </>);
  }

  return (
    <div className=" general bg-grey py-5">
      <Container>
        <Row>
          <Col>
            <div className="title text-center mb-5">
              {"What you can do with our Platform"}
            </div>
          </Col>
        </Row>
        <Row className="align-items-center tab-img-sect">
          <Col xs={12} md={3}>
            <Container fluid className="no-gutters">
              {renderMenu()}
            </Container>
          </Col>
          <Col xs={12} md={9} className={` sp-tab-content ${activeMenu === 0 ? "d-block" : "d-none"}`}>
            <img src={tab1Img} alt="generate helpful analytics" className="sp-img shadow" />
            <div className="hover-dot rd-1">
              <div className="red-dot">
                <span className="icon" />
              </div>
              <div className="message">
                <div className="title">
                  {"Dashboard"}
                </div>
                <div className="desc">
                  {"Access the features of the Q2 Platform seamlessly via the dashboard"}
                </div>
              </div>
            </div>

            <div className="hover-dot rd-2">
              <div className="red-dot">
                <span className="icon" />
              </div>
              <div className="message">
                <div className="title">
                  {"Map"}
                </div>
                <div className="desc">
                  {"Visualise your building and key network features via the platform map"}
                </div>
              </div>
            </div>

            <div className="hover-dot rd-3">
              <div className="red-dot">
                <span className="icon" />
              </div>
              <div className="message message-right">
                <div className="title">
                  {"Active Device Types"}
                </div>
                <div className="desc">
                  {"Discover the device types of network users within your wireless environment"}
                </div>
              </div>
            </div>

            <div className="hover-dot rd-4">
              <div className="red-dot">
                <span className="icon" />
              </div>
              <div className="message message-right">
                <div className="title">
                  {"Active Usage"}
                </div>
                <div className="desc">
                  {"Active traffic on your building's network displayed in real-time"}
                </div>
              </div>
            </div>

            <div className="hover-dot rd-5">
              <div className="red-dot">
                <span className="icon" />
              </div>
              <div className="message ">
                <div className="title">
                  {"Network Downloads"}
                </div>
                <div className="desc">
                  {"Understand network utilisation over specified time periods"}
                </div>
              </div>
            </div>

            <div className="hover-dot rd-6">
              <div className="red-dot">
                <span className="icon" />
              </div>
              <div className="message message-right">
                <div className="title">
                  {"Active Users"}
                </div>
                <div className="desc">
                  {"Understand popular network usage times over an assigned period"}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={9} className={`sp-tab-content ${activeMenu === 1 ? "d-block" : "d-none"}`}>
            <img src={tab2Img} alt="security analytics" className="sp-img shadow" />
          </Col>
          <Col xs={12} md={9} className={`sp-tab-content ${activeMenu === 2 ? "d-block" : "d-none"}`}>
            <img src={tab3Img} alt="generate helpful analytics" className="sp-img shadow" />
          </Col>

          <Col xs={12} md={9} className={`sp-tab-content ${activeMenu === 3 ? "d-block" : "d-none"}`}>
            <img src={tab4Img} alt="generate helpful analytics" className="sp-img shadow" />
          </Col>
          <Col xs={12} md={9} className={`sp-tab-content ${activeMenu === 4 ? "d-block" : "d-none"}`}>
            <img src={tab5Img} alt="generate helpful analytics" className="sp-img shadow" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const InfoSection3 = ({ handleShow }) => {
  return (
    <div style={{ backgroundImage: `url(${advance_platform_bg})` }}>
      <Container className="general font-white advanced-software py-5">
        <Row>
          <Col xs={12} lg={6}>
            <img src={advance_software} alt="Advanced Software" className="sp-img" />
          </Col>
          <Col xs={12} lg={6}>
            <div className="info">
              <div className="title mb-3">
                {"Advanced Software Platform with customisable modular design"}
              </div>
              <div className="desc mb-3">
                {"We operate an innovative software-defined network. Our platform integrates with leading industry systems."}
              </div>
              <button className="btn btn-yellow mb-3 shadow" onClick={handleShow}>
                <i className="fas fa-calendar-star mr-2" />
                {"Book a demo"}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SoftwarePlatform;